<template>
    <div class="row d-flex flex-sm-wrap flex-nowrap overflow-scroll pt-2">
        <div class="col-9 col-md-4 col-sm-6" v-for="(fav,index) in favourites" :key="index">
            <div class="teams">
                <div class="delete-member blue-circle" v-on:click="deleteFav(fav.id)">
                    <img class="img-fluid" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/icons/Delete.svg" alt="">
                </div>
                <div class="team-img">
                    <router-link :to="fav.business.title_slug">
                        <img v-if="fav.business.profile_pic" class="img-fluid" :src="fav.business.profile_pic" alt="" />
                        <img v-else class="img-fluid" src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" alt="" />
                    </router-link>
                </div>
                <div class="team-title">
                    <router-link :to="fav.business.title_slug"><h3>{{fav.business.title}}</h3></router-link>
                    <p class="mb-2">{{fav.business.city}}, {{fav.business.state}}</p>
                    <!-- <div class="member-status text-success">
                        <span>Favourite</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="teams w-100" v-if="favourites.length == 0">
            <div class="add-new d-block mt-5">
                <img src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/employees/no-employee.svg" style="width: 125px">
                <h5 class="fw-bold">No Favourites</h5>
                <p>Favourite some business to show here</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { useToast } from "vue-toastification"
    import axios from 'axios'

    export default {
        setup () {
            const toast = useToast()
            return { toast }
        },
        data(){
            return{
                favourites: [],
            }
        },
        created() {
            this.getFavs()
        },
        methods: {
            getFavs(){
                let thiss = this
                axios.get('favourites/user/'+thiss.$storage.getStorageSync('user').id).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.favourites = response.data.data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            },
            deleteFav(fav_id){
                let thiss = this
                this.$swal({
                    title: 'Are you sure?',
                    text: "You want to delete this!",
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it!',
                    reverseButtons: true,
                    customClass: {
                        confirmButton: 'ond-btn-p',
                        cancelButton: 'ond-btn-w me-3'
                    },
                    buttonsStyling: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('un_favourite/',{fav_id: fav_id}).then(function (response) {
                            //console.log(response.data)
                            thiss.getFavs()
                            thiss.toast.success(response.data.message)
                        })
                        .catch(error => {
                            if (error.response) {
                                thiss.toast.error(error.response.data.message)
                            }
                        });
                    }
                })
            }
        }
    }
</script>
<style scoped>
    /* Hide scrollbar for Chrome, Safari and Opera */
    .overflow-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .overflow-scroll {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
</style>