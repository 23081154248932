<template>
    <AppContainer>
        <Favourites/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/UserDashboardContainer.vue'
import Favourites from '@/components/userDashboard/favourites/Favourites.vue'
export default {
  components: {
    AppContainer,
    Favourites
  }
}
</script>